.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Đặt màu nền và độ mờ cho overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    position: relative;
    /* min-width: 250px; */
    background-color: #fff;
    /* padding: 5px; */
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  }

  /* CSS cho phần header của popup */
.popup-header {
  background-color: #ea0429; /* Màu nền của header */
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
  color: #fff !important; /* Màu chữ của header */
}

.popup-header .close-icon {
  position: absolute;
    /* top: 0px; */
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
}
  