@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css");

body {
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}

.menuTop {
  width: 100%;
  position: fixed;
  background-color: #ea0429;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.boxMenuTop {
  width: 100%;
  max-width: 680px;
}

.textMenuTop {
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  margin: 0px;
  font-size: 0.8rem;
}

.boxNavMenuTop {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.navMenu {
  width: 100%;
  max-width: 1500px;
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

/* Áp dụng CSS này khi bề rộng màn hình < 768px */
@media (max-width: 768px) {
  .navMenu {
    height: 60px;
  }
}

.linkMenuTop {
  color: #ffffff;
  font-weight: 700;
  text-decoration: underline;
}

/* .swiper-button-next,
.swiper-button-prev {
  color: #fff;
} */

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 12px !important;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.menu div li {
  float: left;
}

.menu div li a {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0px 12px;
}

.menu div li a:hover {
  text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
  text-decoration: none;
}

.menu li {
  float: left;
}

.menu li a {
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0px 12px;
}

.menu li a:hover {
  text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
  text-decoration: none;
}

.iconMenu {
  font-size: 25px;
}

.iconMenu:hover {
  color: #ea0429;
}

.menuDrop {
  position: absolute;
  display: none;
  list-style-type: none;
  /* margin: 10px 0; */
  padding: 8px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.menuDrop li {
  padding: 5px;
  float: none !important;
}

li:hover .menuDrop {
  display: block;
}


.footer {
  background-color: #202020;
}

.iconFooter {
  color: #eaeaea;
  font-size: 25px;
  margin: 0px 20px;
}

.border-line {
  border-bottom: 1px solid #404040;
}

.footer h6 {
  color: #eaeaea;
  font-weight: 600;
  margin-bottom: 16px;
}

.footer h5 {
  color: #eaeaea;
  font-weight: 600;
  margin-bottom: 16px;
}

.footer ul li {
  color: #eaeaea;
}

.content {
  padding: 111px 0px 0px 0px;
}

@media only screen and (max-width: 768px) {
  .content {
    padding: 91px 0px 0px 0px;
  }
}

.imgHomeBanner {
  width: 100%;
}

.titleSlide {
  font-weight: 600;
  text-align: center;
  color: #404040;
}

.productBox {
  /* border: 1px solid #ea0429; */
}

.productBoxImg {
  position: relative;
  background-color: #fef5f3;
  display: flex;
  padding: 50px 20px 50px 20px;
  margin-bottom: 20px;
}

.productBoxImg img {
  width: 100%;
  max-width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

.productBoxImg img:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.titleProduct {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font-size: 0.9rem !important;
  font-weight: 600;
  color: #404040;
}

.btn {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: #535353;
  border: 1px solid #eaeaea;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  margin-bottom: 2px;
}

button {
  border: unset !important;
}

.btn:hover {
  background-color: #ea0429;
  color: #fff;
}

.btn-red {
  background-color: #ea0429 !important;
  color: #fff;
}

.btn-red:hover {
  background-color: #aa0016 !important;
  color: #fff;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: unset !important;
}

.required {
  color: #ea0429;
}

.link-forgot {
  font-size: 0.8rem;
  border-bottom: 1px solid #202020;
  font-weight: 600;
  margin-left: 10px;
}

.link-forgot:hover {
  text-decoration: none;
  color: #aa0016;
  border-bottom: 1px solid #aa0016;
}

.noti {
  background-color: #ea0429;
  position: absolute;
  width: 19px;
  height: 19px;
  top: 0px;
  right: 3px;
  display: flex;
  justify-content: center;
  font-size: 0.7rem;
  align-items: center;
  border-radius: 25px;
  color: #fff;
  font-weight: 700;
  z-index: 999999;
}

.cartRemove {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 8px;
  border: 1px solid #ea0429 !important;
  color: #ea0429;
}

.loading-full {
  display: flex;
  position: fixed;
  z-index: 99999;
  background-color: #202020d4;
  width: 100%;
  height: 100%;
  top: 0px;
}

input {
  border: 1px solid #dadada !important;
  border-radius: 4px !important;
  color: #404040 !important;
  background-color: #fff !important;
}

input:focus {
  outline: 2px solid #202020 !important;
}

.alert {
  border-radius: 4px;
}

.cartRemove:hover {
  background-color: #ea0429;
  color: #fff;
}

.rotating {
  -webkit-animation: rotating 1.5s linear infinite;
  -moz-animation: rotating 1.5s linear infinite;
  -ms-animation: rotating 1.5s linear infinite;
  -o-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}

.box-login {
  padding: 25px;
  width: 100%;
  max-width: 450px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background-color: #fff;
  border-radius: 4px;
}

.bg-red {
  background-color: #aa0016;
}

.review {
  padding: 10px 5px;
}

.review img {
  width: 60%;
  max-width: 200px;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  filter: blur(3px);
}

.review.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.swiper-slide.swiper-slide-active.review img {
  width: 100%;
  max-width: 200px;
  filter: none;
  border: 5px solid #fef5f3;
  box-shadow: rgba(254, 245, 243, 0.4) 0px 5px,
    rgba(254, 245, 243, 0.3) 0px 10px, rgba(254, 245, 243, 0.2) 0px 15px,
    rgba(254, 245, 243, 0.1) 0px 20px, rgba(254, 245, 243, 0.05) 0px 25px;
  transition: all 0.5s ease;
}

.box {
  margin-top: 50px;
}

.box-review {
  margin-top: 100px;
  padding-bottom: 200px;
}

.reviewDes {
  background-color: #fef5f3;
  padding: 20px 40px;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.reviewDes:nth-of-type(even) {
  background-color: #fef5f3;
  border-radius: 3px 25px 25px 25px;
  margin-left: 1vw;
}
.reviewDes:nth-of-type(odd) {
  background-color: #ffffff;
  border-radius: 25px 3px 25px 25px;
  margin-right: 1vw;
}

@media (max-width: 768px) {
  .menu li {
    float: none !important;
  }

  .mobiMenuL {
    background-color: #fff;
    position: fixed;
    height: 100vh;
    top: 107px;
    left: 0px;
    width: 100vw;
  }

  .mobiMenuR {
    background-color: #fff;
    position: fixed;
    top: 227px;
    left: 0px;
    width: 100vw;
  }

  .mobiMenuL li {
    padding: 8px;
    height: 40px;
    border-bottom: 1px solid #eaeaea;
  }

  .mobiMenuR li {
    padding: 8px;
    height: 40px;
    border-bottom: 1px solid #eaeaea;
  }

  .menuDrop {
    position: absolute;
    list-style-type: none;
    margin: 8px 0px;
    padding: 0px;
    background-color: #fff;
    box-shadow: unset;
    border-radius: 0px;
    width: 100%;
    background-color: #ea042a18;
  }

  .h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .box {
    margin-top: 30px;
  }
  .h2 {
    font-size: 16px;
  }

  .productBoxImg {
    padding: 30px 10px 30px 10px;
  }

  /* .productBoxImg img {
    height: 150px;
  } */
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.image-bg-color {
  background-color: #fef5f3 !important;
}

/* Begin-Tooltip */
.mytooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.mytooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.mytooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
/* End-Tooltip */

.material-icons.red {
  color: #ea0429;
}
.material-icons.green {
  color: #27633c;
}
.material-icons.inactive {
  color: #c57774;
}

.material-symbols-outlined.red {
  color: #ea0429;
}
.material-symbols-outlined.inactive {
  color: #c57774;
}
.material-symbols-outlined {
  font-size: 1rem;
}

.swiper-button-prev,
.swiper-button-next {
  color: #000000 !important;
}

.product-header{
  font-weight: 660 !important;
}

.custom-width-85 {
  width: 85%;
}

.content-mt {
  margin-top: 2px;
}

/* Begin-CSS các tab trong trang loyalty */
/* Hiển thị nội dung của các tab */
.tab-content {
  display: flex !important;
  padding: 1rem 0;
}

/* Thiết lập kiểu cho nội dung của tab */
.tab-content div {
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  border-top: none;
  border-radius: 0 0 5px 5px;
}

/* Thiết lập một số kiểu mặc định cho tab */
.tab-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
}

/* Điều chỉnh kiểu cho nút khi di chuột qua */
.tab-button:hover {
  background-color: #ccc;
}

/* Thiết lập kiểu cho tab đang được chọn (active) */
.tab-button.active {
  background-color: #ea0429;
  color: #fff;
  border-color: #ea0429;
}
/* End-CSS các tab trong trang loyalty */

/* Begin-Footer CSS */
.footer-background{
  background-image: url("/images/footer.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* position: relative !important; */
  /* height: 100hv !important; */
}
  
.no-padding {
  padding: 0 !important;
}
/* End-Footer CSS */

/* Begin - Sale Icon */
.sale-icon {
  position: absolute;
  width: 16%;
  height: 10%;
  top: 0;
  left: -1;
  background-image: url("/images/sale-icon.png");
  background-size: cover;
  font-size: 50px; /* Adjust font size as needed */
  z-index: 1; /* Ensure the icon appears above the image */
}
/* End - Sale Icon */
